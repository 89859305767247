import Web3 from 'web3/dist/web3.min';
import { createContext, useEffect, useState } from 'react';

const Web3Context = createContext(undefined);

let web3js, provider, params, contract;
let listenersSet = false;

const chainId = 4; // 4 for rinkeby
const contractAddress = require('../assets/address.json')['address'];
const abi = require('../assets/MultiProjectNFT.json')['abi'];

const messages = {
  connected: 'Connected.',
  disconnected: 'Connect your wallet to continue.',
  badNetwork: 'Wrong network, please connect to mainnet.'
};

export function Web3Provider({ children }) {
  const [account, setAccount] = useState(undefined);
  const [message, setMessage] = useState(messages.disconnected);

  const init = async () => {
    // set provider, web3 | many times is OK
    if (!provider)
      provider = 'ethereum' in window ? window['ethereum'] : Web3.givenProvider;
    if (!web3js) web3js = new Web3(provider);
    // set listeners | many times is OK
    setListeners();
    // check chain
    const currentChain = await web3js.eth.getChainId();
    if (currentChain !== chainId) {
      contract = undefined;
      setAccount(undefined);
      setMessage(messages.badNetwork);
      return;
    }
    // set params, contract
    params = { from: account, to: contractAddress };
    if (!contract)
      contract = await new web3js.eth.Contract(abi, contractAddress);
    // set account
    const accounts = await web3js.eth.getAccounts();
    if (accounts && accounts.length > 0) await connect(); // try to connect if we have account
  };

  useEffect(() => {
    init().then();
  }, [contract, account]);

  async function connect() {
    if (account) return account;
    if (!contract) {
      console.error('Connect called with no contract');
      return await init();
    }
    try {
      await web3js.currentProvider
        .request({
          method: 'eth_requestAccounts'
        })
        .then(addresses => {
          if (addresses.length > 0) {
            setAccount(addresses[0]);
          } else {
            console.warn('eth_requestAccounts failed');
            setAccount(undefined);
          }
        });
    } catch {
      console.warn('User cancelled connect.');
    }
  }

  function setListeners() {
    if (!provider || listenersSet) return;
    listenersSet = true;
    provider.on('accountsChanged', accounts => {
      if (accounts.length > 0) return setAccount(accounts[0]);
      console.warn(`Account disconnected`);
      setAccount(undefined);
      setMessage(messages.disconnected);
    });
    provider.on('chainChanged', () => {
      console.warn(`Chain changed`);
      contract = undefined;
      setAccount(undefined);
      setMessage(messages.badNetwork);
    });
  }

  // warning of bad state todo: remove
  useEffect(() => {
    if (account && !contract)
      console.error('Warning: account is ready without contract');
  }, [account, contract]);

  // CONTRACT-SPECIFIC MEMBERS //

  const hiddenProjectIds = ['99999'];

  async function getBaseURI() {
    if (!account) throw new Error('No account');
    return await contract.methods.baseURI().call();
  }

  async function getTokenURIByProject(collectionId, tokenId) {
    if (!account) throw new Error('No account');
    let uri = await contract.methods
      .tokenURIByProject(collectionId, tokenId)
      .call();
    if (!uri.includes('://')) uri = `https://${uri}`;
    return uri;
  }

  async function projectLevelIdToId(collectionId, tokenId) {
    if (!account) throw new Error('No account');
    return await contract.methods
      .projectLevelIdToId(collectionId, tokenId)
      .call();
  }

  async function ownerOf(globalTokenId) {
    if (!account) throw new Error('No account');
    return await contract.methods.ownerOf(globalTokenId).call();
  }

  async function getProject(collectionId) {
    if (!account) throw new Error('No account');
    return await contract.methods.projects(collectionId).call();
  }

  async function getProjectsByUser(address) {
    if (!account) throw new Error('No account');
    let projects = await contract.methods
      .projectsOfTokensOwnedBy(address)
      .call();
    projects = [...new Set(projects)];
    projects = projects.filter(p => !hiddenProjectIds.includes(p));
    return projects;
  }

  async function getTokensOfUserByProject(address, collectionId) {
    if (!account) throw new Error('No account');
    return await contract.methods
      .tokensOwnedByFromProject(address, collectionId)
      .call();
  }

  async function mint(collectionId, amount) {
    if (!account) throw new Error('No account');
    const collection = await contract.methods.projects(collectionId).call();
    const paramsLocal = {
      from: params.from,
      to: params.to,
      value: `${parseInt(collection.mintPrice) * parseInt(amount)}`,
      gas: `${350000 + parseInt(amount) * 250000}` // max 30m
    };
    return await contract.methods.mint(collectionId, amount).send(paramsLocal);
  }

  // ------------------------- //

  init().then();

  const iface = {
    // universal members:
    contractAddress,
    account,
    message,
    connect,
    // contract specific members:
    getBaseURI,
    getTokenURIByProject,
    projectLevelIdToId,
    ownerOf,
    getProject,
    getProjectsByUser,
    getTokensOfUserByProject,
    mint
  };

  return <Web3Context.Provider value={iface}>{children}</Web3Context.Provider>;
}

export default Web3Context;
